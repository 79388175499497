import { render, staticRenderFns } from "./ProductRecall.vue?vue&type=template&id=7c2a2e80&scoped=true&"
import script from "./ProductRecall.vue?vue&type=script&lang=js&"
export * from "./ProductRecall.vue?vue&type=script&lang=js&"
import style0 from "./ProductRecall.vue?vue&type=style&index=0&id=7c2a2e80&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c2a2e80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\zivan.pesic\\Documents\\esbn-front-vue-abbondanza\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c2a2e80')) {
      api.createRecord('7c2a2e80', component.options)
    } else {
      api.reload('7c2a2e80', component.options)
    }
    module.hot.accept("./ProductRecall.vue?vue&type=template&id=7c2a2e80&scoped=true&", function () {
      api.rerender('7c2a2e80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/wordpress/ProductRecall.vue"
export default component.exports